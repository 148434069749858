import React from 'react'
import './Footer.css';
const Footer=()=> {
    return (
      <footer class="footer">
        <div class="footer-text">
            <p>Copyright &copy; 2023 by Shareef Ali | All Rights Reserved.</p>
        </div>

        <div class="footer-iconTop">
            <a href="#home"><i class='bx bx-up-arrow-alt'></i></a>
        </div>
    </footer>
    )
  }

export default Footer